import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Containers/Home';
import Pages from './Containers/Pages';
import Contact from './Containers/Contactus';
import Register from './Containers/Register';
import OurTeam from './Containers/Pages/our_team';
import Career from './Containers/Pages/career';
import AadharEnabledPaymentSystem from './Containers/Pages/adharenabled';
import PrepaidRecharge from './Containers/Pages/prepaid_recharge';
import BillPaymentsBBPS from './Containers/Pages/bill_payment';
import MicroAtm from './Containers/Pages/micro_atm';
import PanCardCenter from './Containers/Pages/pan_card_center';
import AadharPay from './Containers/Pages/aadhar_pay';
import Retailer from './Containers/Pages/retailer';
import Distributor from './Containers/Pages/distributor';
import Api from './Containers/Pages/api';
import Faqs from './Containers/Pages/faqs';
import TermsConditions from './Containers/Pages/terms_conditions';
import PrivacyPolicy from './Containers/Pages/privacy_policy';
import RefundCancellation from './Containers/Pages/refund_cancellation';




function App() {
  return (
    <div className="App">
       <Router>
        <Routes>
         <Route exact path="/" activeClassName="active" element={<Home/>}/>
         <Route exact path="/contact-us" activeClassName="active" element={<Contact/>}/>
         <Route exact path="/register" activeClassName="active" element={<Register/>}/>
         <Route exact path="/career" activeClassName="active" element={<Career/>}/>
         <Route exact path="/our-team" activeClassName="active" element={<OurTeam/>}/>
         <Route exact path="/aadhar-enabled-payment-system" activeClassName="active" element={<AadharEnabledPaymentSystem/>}/>
         <Route exact path="/prepaid-recharge" activeClassName="active" element={<PrepaidRecharge/>}/>
         <Route exact path="/bill-payments-bbps" activeClassName="active" element={<BillPaymentsBBPS/>}/>
         <Route exact path="/microatm" activeClassName="active" element={<MicroAtm/>}/>
         <Route exact path="/pan-card-center" activeClassName="active" element={<PanCardCenter/>}/>
         <Route exact path="/aadhar-pay" activeClassName="active" element={<AadharPay/>}/>
         <Route exact path="/retailer" activeClassName="active" element={<Retailer/>}/>
         <Route exact path="/distributor" activeClassName="active" element={<Distributor/>}/>
         <Route exact path="/api" activeClassName="active" element={<Api/>}/>
         <Route exact path="/faqs" activeClassName="active" element={<Faqs/>}/>
         <Route exact path="/terms-conditions" activeClassName="active" element={<TermsConditions/>}/>
         <Route exact path="/privacy-policy" activeClassName="active" element={<PrivacyPolicy/>}/>
         <Route exact path="/refunds-cancellations-policy" activeClassName="active" element={<RefundCancellation/>}/>






         <Route exact path="/:slug" activeClassName="active" element={<Pages/>}/>
         </Routes>
         </Router>

    </div>
  );
}

export default App;
