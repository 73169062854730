import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function PrivacyPolicy() {
    return (
        <>
            <div class="wrapper">
                <Header />
                <section class="page-title-layout1 page-title-light bg-overlay bg-parallax text-center bg-img" style={{ backgroundImage: `url("assets/images/page-titles/1.jpg")`, backgroundSize: "cover", backgroundPosition: "center center" }}>
                    {/* <div class="bg-img"><img src="assets/images/page-titles/1.jpg" alt="background"/></div> */}
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 offset-xl-3">
                                <h1 class="pagetitle__heading">Privacy Policy</h1>
                                <nav>
                                    <ol class="breadcrumb justify-content-center mb-0">
                                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                                        {/* <li class="breadcrumb-item"><a href="about-us.html">Company</a></li> */}
                                        <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div class="content">
                            <div class="row justify-content-md-center">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4>A. Introduction</h4>
                                            <p>This Privacy Policy, sometimes known as the Policy, details how Rocket
                                                Paisa gathers, uses, stores, retrieves, shares, and transfers Your
                                                information which can include private or sensitive material or data
                                                Information.   When you use our facilities and services (Services) or
                                                access, connect with, or use Our website, system, internet site, or
                                                smartphone application, We may be able to get this information from
                                                you.  Regarding this Policy, you should be aware of certain quite crucial
                                                facts.  It solely addresses the data we learn about your use of the
                                                Services or Activities.</p>
                                                    <p>You fully and completely agree to follow the rules and policies listed
                                                        above when you use the Platform and the Services.   We will only
                                                        collect, use, process, store, retrieve, share, or send your information in a
                                                        way that is legal and follows this Policy and any other laws that apply.
                                                        These laws and the rules made under them may change from time to
                                                        time.   Our actions also follow the rules, instructions, notices,
                                                        instructions, or other orders issued by the Reserve Bank of India and
                                                        any other Indian regulators. They also follow any other relevant acts,
                                                        regulations, or rules issued by the Parliament of India or the Government
                                                        of India. These are all called "Applicable Laws."  These rules cover the
                                                        Facilities that can be found on the Platform.</p>
                                                    <p>All relevant laws, rules, and policies about using the Platform are
                                                        referred to and included in this Policy. This includes those that are set
                                                        out in the Company's rules and conditions as well as any other policies
                                                        or terms.   Please read this Policy carefully to know how we will use any
                                                        information you give us.   You agree to follow this Policy if you use the
                                                        Platform.   When you use the Site or the Services, you agree that We
                                                        can collect, process, store, move, use, share, and handle Your
                                                        Information in the ways set out in this Policy.   If you don't agree with the
                                                        ways We use your information in this Policy, please don't give Us your
                                                        information or use, access, or interact with the Platform and/or Services.</p>
                                                    <h6>You promise the company that:</h6>
                                                    <ul>
                                                        <li>The information you give us is real, correct, up-to-date, and true; and You have all the rights, permissions, and authorizations we may need to get it. When you give us information, we will only keep it, use it, transfer it, view it, or handle it if you agree.</li>
                                                        <li>There are no deals, rules, policy records, judgments, orders, or laws that you will break. It is your job to make sure that the details you give us are always right and up to date.</li>
                                                        <li>Many of the records about you will be kept on computers, but some may also be kept on paper. We may make deals with outside groups to keep your data or information safe or to process it.</li>
                                                        <li>These third parties may have their own rules about how to keep your data or information safe. If those third parties want to keep your information safe, we will expect them to follow acceptable security rules.</li>
                                                        <li>People under the age of 18 and people who have been banned or stopped from the Platform by Us are not allowed to use it or its services. By using the Platform, You promise that you are not less than 18 years old and that You have not been banned from using our facilities or the Site for any other reason or blocked on our platform before. Aside from that, you promise that You are fully authorized and able to sign this Agreement and follow all of its terms, including those in this Policy. Lastly, you may not pretend to be someone or something else, or lie about your name, age, or relationship with someone or something else.</li>
                                                    </ul>

                                                    <h4>B. What do we gather?</h4>
                                                    <ul>
                                                        <li>You give Us personal information like your name, email address,
                                                            phone number, home address, date of birth, and/or information
                                                            from papers like your ID card, travel document, Identification
                                                            number, PAN card, voter ID, driver's license, and/or information
                                                            about your education so that We can provide you with different
                                                            services.</li>
                                                        <li>You'll need images, photos of papers, live photos, or videos to use
                                                            any of our services.</li>
                                                        <li>information about jobs (like what they do, where they worked, and their employment background), business information, GST information (like getting GST from a PAN or GST number), income and/or rewards) as part of our record-keeping for different services or because the law requires it.</li>
                                                        <li>Demographic facts about the user.</li>
                                                    </ul>

                                                    <h6>Information and transactions about money</h6>
                                                    <ul>
                                                        <li>When you ask for some of Our Services, we need financial information, like your credit background.</li>
                                                        <li>Your information, such as your buy and the history of transactions, balances, and payment information, so that we can keep records of your virtual account or trade balance account as required by law.</li>
                                                        <li>Your phone number, as well as transactional and advertising SMS and app alerts.</li>
                                                    </ul>
                                                    <h6>Finding and stopping fraud</h6>
                                                    <ul>
                                                        <li>When you use Our Platform, we get information from your phone, such as other IDs, contact information like where the phone is, information about the device like storage facilities. multimedia files and documents, IMEI, network provider information, SIM Identification Number, text messages, model.</li>
                                                        <li>WiFi details can be accessed via your device's SSID, which alerts users to the network's security.</li>
                                                        <li>Your location, as close as possible or exactly where you are, based on the most accurate available sources, such as GPS, cell network, WiFi, IP, and more.</li>
                                                    </ul>
                                                    <h4>Different Things</h4>
                                                    <ul>
                                                        <li>Your feedback or survey answers that we use to figure out what you think.</li>
                                                        <li>Analysis of how people act, including their likes and dislikes and hobbies.</li>
                                                    </ul>
                                                    <p>It is your right to change your mind about letting us collect and use your private data at any time by following the steps in section J below.  Please keep in mind, though, the fact that we won't be able to remove your information linked to Know Your Customer (KYC), your banking information and activities, or your scam detection and mitigation.</p>
                                                    <ul>
                                                        <li>Please <a target="_blank" href="https://www.rbi.org.in/Scripts/BS_ViewMasDirections.aspx?id=11566">click here</a> to see the KYC-related guideline.</li>
                                                        <li>Guidelines that apply to financial information and transactions - <a target="_blank" href="https://m.rbi.org.in/Scripts/BS_ViewMasDirections.aspx?id=12156">Click here</a></li>
                                                        <li>Instructions for Preventing and Detecting Fraud - <a target="_blank" href="/privacy-policy.pdf">Click here</a></li>
                                                    </ul>

                                                    <h4>C. How we use the personal data we collect?</h4>
                                                    <p>We might use the information you give us to:</p>
                                                    <ol>
                                                        <li> take care of business. </li>
                                                        <li> run the Services and make them more useful to you.</li>
                                                        <li> part of our efforts to offer or improve Our Services, including their security and quality, we will do research and analytics. This will help us figure out problems and see how people are using our services.</li>
                                                        <li> look over and handle the needs you've given us for Services and any orders or requests you've sent us about these Services.</li>
                                                        <li> keep you up to date on changes to the Services and their terms and conditions, as well as the terms and conditions of the Platform.</li>
                                                        <li> take up or look into any claims, complaints, or disagreements.</li>
                                                        <li> answer your questions or address the feedback you send;  Notify people about important things, like sales or uploads and modifications to Our Policy.</li>
                                                        <li> make sure you are who you say you are so We can provide Services to you.</li>
                                                        <li> do credit checks, background checks, or due inquiry checks as the law allows us to.</li>
                                                        <li> keep an eye on and sometimes review the Services.</li>
                                                        <li> do reports for finances, regulations, and management, as well as to make and keep up with different risk management models.</li>
                                                        <li> the goal of auditing and keeping records.</li>
                                                        <li> that we can show you ads that are important to you and your interests, as well as certain deals and offers, outcomes for searches, and services that are based on your location.</li>
                                                        <li> follow the rules and laws that apply to us, as well as any legal orders or regulatory directions we receive.</li>
                                                        <li>, We May Communicate With You Through Our Newsletter.</li>
                                                    </ol>
                                                    <h4>D. Getting and using information that is not personal</h4>
                                                        <p>Every time you use the Platform, we may gather some information that does not identify you.  Not personally identifiable information can be anything other than the Personal Information listed above. It can also include things like your browser name, the type of computer you're using, and technical information about how you connected to our Platform, such as the operating system and internet service providers you used, among other things.</p>
                                                        <p>We put this information together and use it to give you better information and figure out which parts of the Programs and/or the Platform you're most interested in.</p>
                                                        <p>Any time we merge non-personal data with personal data, we'll regard the merged data as personal data for the time being.</p>
                                                    <h4>D.1. Giving information to the authorities</h4>
                                                        <p>We may give your personal information to the courts, law enforcement, or any other government, legislative, or legal organization if they ask us to in connection with a case they are currently handling.</p>
                                                    <h4>E. Your safety</h4>
                                                    <p>We will do everything we can to keep Your Personal Information safe.  We use the right gathering, storing, processing, and security methods to keep Your Personal Information, login credentials, payment details, and data stored in our Platform/ecosystem safe from people who shouldn't be able to see, change, share, or delete it.</p>
                                                    <p>As required by the IT Laws, we use adequate safety practices and processes to protect the Personal Information you give us.  We have a detailed information security program along with information privacy regulations that include supervisory, technical, tactical, and physical security controls that are appropriate for the type of Personal Information we are collecting and the way we do business.</p>
                                                    <p>When you utilize our platform, any service provider or service facilitator can see the personal information you have shared.  In these situations, you are in charge of the private data you send.  For instance, if you put your full name and email address in a blog or forum post, everyone can see that.  You are asked to be careful when you use these tools.</p>
                                                    <p>We work hard to make sure your information is safe and secure, but the internet has many holes that mean we can't promise that all the information you give us will be completely safe.  Always keep in mind that there is nothing such as 100% safety.  If We learn that Your Information has been shared in a way that doesn't follow this Policy, We will do our best to let You know as soon as possible and as legally allowed. This includes telling you what kind of information was shared and how much it was shared.</p>

                                                    <h4>F. How long personal information is kept</h4>
                                                    <p>We will keep Your Personal Information for as long as we need it to do what we're supposed to do with it or until You tell us not to, whichever comes first.  After that, we will delete the Personal Information, unless the law requires us to keep something like a copy.</p>
                                                    <h4>F. 2. Giving out personal details</h4>
                                                    <p>We may hire outside service providers to help us run our business, offer our services, or do things like send out newsletters and polls on our behalf.  This is the only time we may give these third parties your personal information.  We only share private data with people who "need to know" it.</p>
                                                    <p>Usually, the third-party service providers We work with will only gather, use, and share Your information as much as they need to to do the work or services they provide Us.  We share the information safely in these situations and do what's commercially sensible to make sure that all recipients keep it secret and sign agreements to that effect.  While we have to give them information, some third-party service providers, like banks, financial institutions, payment aggregators, or credit information companies, have their own privacy rules that apply to that information.  Before giving your information to these third-party service providers, you should read their privacy rules to know how they will handle it.  If you leave the Platform or are sent to a third-party website or app, this Policy and the Platform's Terms and Conditions will no longer apply. Instead, the privacy policy of that other website will control your use of that website.  We won't be responsible for what the third-party service provider does or doesn't do.</p>
                                                    <h4>F.3 Information Given</h4>
                                                    <p>These are the outside groups that will receive your personal information: </p>
                                                    <ol>
                                                        <li>Partners in payment handling, service providers, and so on:  You give us information, and we may give it to financial institutions and service providers like card companies, payment processors, lead organisers, collection service providers, NBFC collaborators, and partners in banking to help with transactions.</li>
                                                        <li>Providers of Information Technology Services: We may disclose Your Data to providers of information technology services who help us run the Platform and do analytics and research.</li>
                                                        <li>In order to comply with applicable laws, we may disclose your information to third parties who assist in verifying your identity and other relevant details in order to complete the necessary identification and verification processes.</li>
                                                        <li>In order to fulfil our legal responsibilities, we may disclose Your Information to the appropriate government or law enforcement agency, as well as other relevant authorities and regulatory organizations.</li>
                                                        <li>Affiliate marketers, business developers, and salespeople: We may share Your Information with affiliate marketers, business developers, and salespeople in order to make Our Platform better and help with marketing or company development.</li>
                                                        <li>Affiliates and subsidiaries of the Company: We may share Your Information with our subsidiaries, affiliates, and group companies so that they can provide certain products, services, and features more efficiently, as well as for internal corporate/business restructuring and auditing purposes, as long as we follow the law.</li>
                                                        <li>In any other situation where We have Your permission for any other reason We tell You about when You give us the information.</li>

                                                    </ol>
                                                    <p>We are dedicated to always keeping this kind of information safe and giving the best level of care possible with the technology we have access to right now.</p>
                                                    <h4>G. Websites that are not ours</h4>
                                                    <p>Ads and other content on the Platform may have links to our partners' and third parties' sites and services. These could be brands, advertisers, distributors, or other third parties.  The sites and services that are linked to or from our Platform have their own rules about what they can and can't do. We have no control over the content or links that show on those sites and platforms.  It's also possible that these sites and platforms, along with their material and links, are always changing.  There may be different privacy and customer service rules for each of these sites and platforms.  When you browse and connect with any other website, even ones that link to the Services, you must follow the rules and guidelines set by that website or platform.</p>
                                                    <p>There are times when we work together with outside groups to provide the Platform or the Services.  It is important to remember that Our Policy will no longer apply if You give Information to a third party or click on a link to a third-party site while using the Platform. We are not responsible for how these third parties handle your privacy.</p>
                                                    <h4>H. Making changes to this rule</h4>
                                                    <p>Our sole judgement gives us the right to modify, update, edit, add to, or remove any part of this Policy at any time. </p>
                                                    <p>You should check this page often to see if this Policy has been changed.  You agree and understand that it is your responsibility to check this Policy often and be aware of any changes.</p>
                                                    <h4>I. First, you agree to these terms.</h4>
                                                    <p>You agree and understand that you have to follow this Policy when you use the Services or access the Platform.  By using Our Services, you are giving us permission to collect, use, process, and disclose any information about you, both personally identifiable and otherwise, in accordance with the provisions of this Privacy Policy.  Please don't use the Services if you don't agree with these rules.  If you continue to use the Platform or Our Services after we make changes to this Policy, it means you understand those changes and agree to be bound by the new terms and conditions.</p>
                                                    <p>You admit that you give your consent freely, specifically, with full knowledge, without any conditions, and without any doubt.  By giving this permission, you agree that We can use your personal information for the reasons set out in this policy.</p>

                                                    <h4>J. Your Legal Rights</h4>
                                                    <p>No matter what time, you can take back your permission for Your Personal Information to be collected and used.  You can make a request to Us by clicking on this link: <a target="_blank" href="https://rocketpaisa.com/delete-account">click here</a>.  We will delete your personal information from our records as soon as we get your request. </p>
                                                    <p>Be advised that there are specific scenarios in which We are compelled by law to handle or store your Personal Data in accordance with the relevant laws.  These records and data will be made available for regulators or other research groups to look over as needed by the law.  Please click on this link to find out more about the information we can delete and the information we are required by law to keep.</p>
                                                    <p>Sending an email to <a target="_blank" href="mailto:support@rocketpaisa.in">support@rocketpaisa.in</a> will let you see, change, finish, or fix any incorrect Personal Information that we have on file for you.</p>
                                                    <p>You can get the following information about your personal information by sending an email to <a target="_blank" href="mailto:support@rocketpaisa.in">support@rocketpaisa.in</a>:</p>
                                                    <ul>
                                                        <li>a list of the personal information about you that We are handling and the tasks that are being done with that information</li>
                                                        <li>we will tell you the names of all the third parties with whom We have shared Your Personal Information and what Personal Information was shared; and</li>
                                                        <li>any other information that the law requires which is linked to Your Personal Information and how it is being used.</li>
                                                    </ul>

                                                    <h4> K. Get in Touch With Us</h4>
                                                    <p>You can email or call Our Grievance Officer at any of the following numbers.  Our complaints officer will try to make things right for you quickly: </p>
                                                    <ul>
                                                        <li><b>Name:</b> DIPESH KUMAR YADAV</li>
                                                        <li><b>Designation:</b> Senior Technical</li>
                                                        <li><b>Email:</b> <a target="_blank" href="mailto:support@rocketpaisa.in">support@rocketpaisa.in</a></li>
                                                    </ul>

                                                    <h4>L. Rules for Law and Dispute Resolution</h4>
                                                    <p>The only reason you can view content through the Platform is to make things easier for you unless it says otherwise.  We own and run the Platform and Services, and we don't promise that the Content and Activities are acceptable or can be used in other places.  If You use the Platform from a place apart from where the Services and Activities are controlled and run, You are responsible for following the rules in that place.</p>
                                                    <p>Only the rules of India will apply to this Policy and decide how it should be read.  Any problems that come up with this Policy will only be dealt with by the courts in Delhi, India.</p>

                                                    <h4>M. Developer's Account</h4>
                                                    <p>Codexo Software pvt. ltd.</p>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default PrivacyPolicy
